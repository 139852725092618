/**
 * Enables synchronization across tabs via localStorage's storage event
 * See https://developer.mozilla.org/en-US/docs/Web/API/Window/storage_event
 * Migrated from https://bitbucket.org/derstandard/clientside/src/1a5e1ebaa286936b4b86b0e0e9d5c5f5acd9767e/Source/ClientSide/src/js/shared/infrastructure/storageSync.js?at=master#storageSync.js
 */

import { Logger } from "./log";

const log = new Logger({ logger: "storageSync" });

const storageValue = "true";
let storageKey: string;
let callback: () => void;

/**
 * Initializes module and registers event listener for storage events
 * @param {String} identifier name of this storageSync instance
 * @param {Function} handler runs when storageSync is triggered
 */
function initialize(identifier: string, handler: { (): void }) {

  if (!window.StorageEvent) {
    return;
  }

  storageKey = `storageSync_${identifier}`;
  callback = handler;

  log.debug(`Registering eventlistener for storageKey ${storageKey}`);
  window.addEventListener("storage", event => onStorageEvent(event));
}

/**
 * Triggers synchronization
 */
function synchronize() {

  if (!window.StorageEvent) {
    return;
  }

  try {

    log.debug(`Synchronizing storageKey '${storageKey}'...`);
    window.localStorage.setItem(storageKey, storageValue);
    window.localStorage.removeItem(storageKey);

  } catch (e) {

    log.debug("localStorage unavailable");
    log.debug("Storage sync failed");
  }
}

/**
 * Handles change event on localStorage
 * @param {type} storageEvent
 */
function onStorageEvent(storageEvent: StorageEvent) {

  if (!storageEvent) {
    return;
  }

  if (storageEvent.key === storageKey && storageEvent.newValue === storageValue) {

    log.debug(`Triggering callback for storageKey '${storageKey}' ...`);
    if (typeof callback === "function") {
      callback();
    }
  }
}

export {
  initialize,
  synchronize
};
