import { Auth, Amplify, Hub } from "aws-amplify";
import { Logger } from "./log";
import { Notifier } from "./notifier";
import { settings } from "./settings";
import awsconfig from "./aws-exports";
import cookies from "js-cookie";
import type { CognitoUserSession } from "amazon-cognito-identity-js";

const log = new Logger({ logger: "AmplifyAuthWrapper" });
let currentSessionRequest: Promise<CognitoUserSession> | null = null;

/**
 *
 */
class AmplifyAuthWrapper implements DstAuth.AmplifyWrapper {
  #notifier;

  constructor() {
    Hub.listen("auth", this.#onAuthEvent.bind(this));
    log.info("configuring auth...", awsconfig);
    this.#notifier = new Notifier();
    Amplify.configure(awsconfig);
  }

  #onAuthEvent(data: DstAuth.EventData) {
    if (!data) {
      return;
    }

    log.info(`Event: '${data.payload.event}', Message: '${data.payload.message}'`);

    switch (data.payload.event) {
      case "configured":
        break;
      case "tokenRefresh":
        this.#notifier.notify();
        break;
      case "signOut":
        this.#notifier.notify();
        break;
    }
  }

  async getSession() {
    try {
      // remove any existing _csid cookie from all clients
      log.debug("Removing csid");
      cookies.remove("_csid", {
        domain: settings.isDe
          ? typeof settings.cookieDomainDe === "string"
            ? settings.cookieDomainDe
            : undefined
          : typeof settings.cookieDomain === "string"
            ? settings.cookieDomain
            : undefined
      });

      log.info(`checking for session... at ${document.location.hostname}`);

      log.debug("Checking for promise...");
      if (!currentSessionRequest) {
        log.debug("No promise. Continue...");
        // workaround: we have a refreshtoken, but no idtoken => create mock idtoken to override amplify's condition of existing idtoken value (content doesn't matter)
        const keyPrefix = "CognitoIdentityServiceProvider";
        const lastUserKey = `${keyPrefix}.${awsconfig.userPoolWebClientId}.LastAuthUser`;
        const lastUser = awsconfig.storage.getItem(lastUserKey);

        if (lastUser) {
          log.debug("Probing for existing refreshToken but missing idToken...");
          const refreshTokenKey = `${keyPrefix}.${awsconfig.userPoolWebClientId}.${lastUser}.refreshToken`;
          const idTokenKey = `${keyPrefix}.${awsconfig.userPoolWebClientId}.${lastUser}.idToken`;
          const refreshToken = awsconfig.storage.getItem(refreshTokenKey);
          const idToken = awsconfig.storage.getItem(idTokenKey);

          if (refreshToken && !idToken) {
            log.debug("Probing successful.");
            awsconfig.storage.setItem(idTokenKey, "1");
          }
        }

        currentSessionRequest = Auth.currentSession();
      } else {
        log.debug("promise exists.");
      }

      log.debug("Awaiting promise...");
      const currentSession = await currentSessionRequest;

      log.info("session exists.");
      log.debug("current session:", currentSession);
      return currentSession;
    } catch (error) {
      log.info("No current session exists.");

      return null;
    } finally {
      currentSessionRequest = null;
    }
  }

  async signOut({ global }: DstAuth.SignOutOpts) {
    try {
      log.info("Signing out user...");
      await Auth.signOut({ global });
      log.info("Signed out");
    } catch (error) {
      log.info("Sign out failed", error);
    }
  }
}

export { AmplifyAuthWrapper };
