const isDe = document.location.hostname.includes(process.env.DE_HOSTNAME_PATTERN || "");

export const settings = {
  appName: process.env.APP_NAME,
  cognito: {
    userPoolId: process.env.COGNITO_USER_POOL_ID,
    clientId: process.env.COGNITO_CLIENT_ID,
  },
  cookieDomain: process.env.COOKIE_DOMAIN,
  cookieDomainDe: process.env.COOKIE_DOMAIN_DE,
  cookieUseSecure: process.env.COOKIE_USE_SECURE === "true",
  deHostnamePattern: process.env.DE_HOSTNAME_PATTERN || "",
  logLevel: process.env.LOG_LEVEL || "warn",
  isDe,
  signInUri: isDe ? process.env.SIGNIN_URI_DE : process.env.SIGNIN_URI,
  tokenWorkflowUrlWhitelist: process.env.TOKENWORKFLOW_URL_WHITELIST || "",
  targetOriginAllowList: process.env.TARGET_ORIGIN_ALLOWLIST || "",
} as const;
