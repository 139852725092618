type LoginContext = {
  color: string;
  description: string;
  iconPath: string;
  title: string;
}

let loginContext: LoginContext = {
  color: '',
  description: '',
  iconPath: '',
  title: ''
};

const knownContexts = ['leseliste', 'meinForum'];

function contextMapper(context: string) {
  if (typeof context === 'undefined' || !context) {
    return;
  }

  switch (context) {
    case 'leseliste':
      loginContext.title = 'Ihre Leseliste immer und überall';
      loginContext.description = 'Melden Sie sich an, um Inhalte auf Ihre Leseliste zu setzen und auf all Ihren Geräten abzurufen.';

      // TODO: once more loginContext.iconPaths are added, it should be considered to dynamically import them from a separate file
      loginContext.iconPath = 'M17,18L12,15.82L7,18V5H17M17,3H7A2,2 0 0,0 5,5V21L12,18L19,21V5C19,3.89 18.1,3 17,3Z';

      // needs to be in this rgb format in order to use rgb() for opacity
      loginContext.color = '232 134 5';
      break;

    case 'meinForum':
      loginContext.title = 'Eigenes Forum eröffnen';
      loginContext.description = 'Eröffnen Sie ein Forum und diskutieren Sie Ihr Thema mit der Community.';

      // TODO: once more loginContext.iconPaths are added, it should be considered to dynamically import them from a separate file
      loginContext.iconPath = 'M18 7H21.5C22.325 7 23 7.675 23 8.5V22L20 19H9.5C8.675 19 8 18.325 8 17.5V16H9.5V17.5H20.6L21.5 18.4V8.5H18V7Z M1 3.5C1 2.6675 1.675 2 2.5 2H14.5C14.8978 2 15.2794 2.15804 15.5607 2.43934C15.842 2.72064 16 3.10218 16 3.5V12.5C16 12.8978 15.842 13.2794 15.5607 13.5607C15.2794 13.842 14.8978 14 14.5 14H4L1 17V3.5Z';

      // needs to be in this rgb format in order to use rgb() for opacity
      loginContext.color = '60 131 246';
      break;

    default:
      null;
  }
}

export function renderLoginContext(context: string): string {
  if (!knownContexts.includes(context)) {
    return '';
  }

  contextMapper(context);

  return `
    <style>
    .login-context {
        align-items: center;
        background-color: rgb(${loginContext.color} / 0.1);
        color: var(--theme--on-surface, #222);
        display: flex;
        flex-direction: column;
        padding: 35px 25px 25px 25px;
        text-align: center;
      }

      .login-context .icon {
        background-color: rgb(${loginContext.color} / 0.12);
        border-radius: 100%;
        line-height: 0;
        padding: 15px;
      }

      .login-context svg {
        color: rgb(${loginContext.color});
        height: 24px;
        width: 24px;
      }

      .login-context h1 {
        font: var(--w1-heading);
        margin: 15px 0 5px;
      }

      .login-context p  {
        font: var(--w3-info);
        margin: 0;
        opacity: 0.7;
        text-align: center;
      }

      @media (min-width: 768px) {
        .login-context h1 {
          font: var(--w3-heading);
        }

        .login-context p  {
          font: var(--w4-info);
        }

        .login-context svg {
          height: 32px;
          width: 32px;
        }
      }
    </style>

    <header class="login-context">
      <div class="icon">
        <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path fill="currentColor" d="${loginContext.iconPath}" />
        </svg>
      </div>
      <h1>${loginContext.title}</h1>
      <p>${loginContext.description}</p>
    </header>
    `.replace(/\s{2,}/g,''); // makeshift minification, due to lack of proper tooling
}
