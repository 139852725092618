import { AmplifyAuthWrapper } from "./amplifyAuthWrapper";
import { events } from "./events";
import { Logger } from "./log";

const log = new Logger({ logger: "startupTasks" });

type Args = {
  amplifyAuthWrapper: DstAuth.AmplifyWrapper;
};

/**
 * Register event listeners for signout - only for backwards-compatibility with clientside!
 * @param {Object} options
 * @param {Object} options.amplifyAuthWrapper
 */
function initialize({ amplifyAuthWrapper }: Args) {

  log.debug("Triggered run");
  const amplify = amplifyAuthWrapper || new AmplifyAuthWrapper();
  window.addEventListener(events.dst.user.loggedOut, () => {
    log.debug(`Received '${events.dst.user.loggedOut}' event`);
    return amplify.signOut({ global: false });
  });
}

export {
  initialize
};
