import { AmplifyAuthWrapper } from "./amplifyAuthWrapper";
import { initialize as initializeEventlistener } from "./eventlistener";
import AuthApi from "./authApi";

const amplifyAuthWrapper = new AmplifyAuthWrapper();
const authApi = new AuthApi({ amplifyAuthWrapper });

initializeEventlistener({ amplifyAuthWrapper });

export {
  authApi as api
};
