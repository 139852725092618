import { Logger } from "./log";
import { synchronize, initialize } from "./storageSync";

const log = new Logger({ logger: "notifier" });

class Notifier {

  constructor() {

    initialize("auth-js", this.#onStorageSync.bind(this));
  }

  static StateChangedEvent = "dst.auth.stateChanged";

  #sendMessage() {

    log.info("Sending stateChanged message...");
    window.postMessage({ eventName: Notifier.StateChangedEvent }, document.location.origin);
  }

  #onStorageSync() {

    this.#sendMessage();
  }

  notify() {

    synchronize();
    this.#sendMessage();
  }
}

export {
  Notifier
};
