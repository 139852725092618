import { default as CookieAndLocalStorage } from "./cookieAndLocalStorage";
import { settings } from "./settings";

const config = {

  userPoolId: settings.awsUserPoolsId,
  userPoolWebClientId: settings.awsUserPoolsWebClientId,
  authenticationFlowType: "USER_PASSWORD_AUTH",
  storage: new CookieAndLocalStorage({
    domain: settings.isDe ? String(settings.cookieDomainDe) : String(settings.cookieDomain),
    path: "/",
    expires: Number(settings.cookieExpiryDays),
    secure: Boolean(settings.cookieUseSecure),
    cookieKeySuffixes: "LastAuthUser,refreshToken,idToken,deviceKey"
  }),
  // Hosted UI Configuration
  "oauth": {}
};

export default config;
