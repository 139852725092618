const events = {
  dst: {
    user: {
      /**
       * Triggers when page received user data for currently logged in user
       */
      userDataReceived: "dst.user.userDataReceived",

      /**
       * Triggers when page received idToken for currently logged in user
       */
      idtokenreceived: "dst.user.idtokenreceived",

      /**
       * Triggers when currently logged in user logged out
       */
      loggedOut: "dst.user.loggedOut",

      /**
       * Triggers when user logged in
       */
      loggedIn: "dst.user.loggedIn",

      /**
       * Triggers when no logged in user could be determined
       */
      userNotLoggedIn: "dst.user.userNotLoggedIn",

      /**
       * Triggers when user/password login failed because of invalid credentials
       */
      invalidcredentials: "dst.user.invalidcredentials",

      /**
       * Triggers when some user data changes and a session refresh is necessary
       */
      userDataChanged: "dst.user.userDataChanged"
    }
  }
};

export {
  events
};
