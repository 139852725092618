type LoginContext = {
  color: string;
  description: string;
  iconPath: string;
  title: string;
}

let loginContext: LoginContext = {
  color: '',
  description: '',
  iconPath: '',
  title: ''
};

const knownContexts = ['leseliste'];

function contextMapper(context: string) {
  if (typeof context === 'undefined' || !context) {
    return;
  }

  switch (context) {
    case 'leseliste':
      loginContext.title = 'Ihre Leseliste immer und überall';
      loginContext.description = 'Melden Sie sich an, um Inhalte auf Ihre Leseliste zu setzen und auf all Ihren Geräten abzurufen.';

      // TODO: once more loginContext.iconPaths are added, it should be considered to dynamically import them from a separate file
      loginContext.iconPath = 'M14 10H3V12H14V10ZM14 6H3V8H14V6ZM3 16H10V14H3V16Z M18 14V10H16V14H12V16H16V20H18V16H22V14';

      // needs to be in this rgb format in order to use rgb() for opacity
      loginContext.color = '232 134 5';
      break;

    default:
      null;
  }
}

export function renderLoginContext(context: string): string {
  if (!knownContexts.includes(context)) {
    return '';
  }

  contextMapper(context);

  return `
    <style>
    .login-context {
        align-items: center;
        background-color: rgb(${loginContext.color} / 0.1);
        color: var(--theme--on-surface, #222);
        display: flex;
        flex-direction: column;
        padding: 35px 25px 25px 25px;
        text-align: center;
      }

      .login-context .icon {
        background-color: rgb(${loginContext.color} / 0.12);
        border-radius: 100%;
        line-height: 0;
        padding: 15px;
      }

      .login-context svg {
        color: rgb(${loginContext.color});
        height: 24px;
        width: 24px;
      }

      .login-context h1 {
        font: var(--w1-heading);
        margin: 15px 0 5px;
      }

      .login-context p  {
        font: var(--w3-info);
        margin: 0;
        opacity: 0.7;
        text-align: center;
      }

      @media (min-width: 768px) {
        .login-context h1 {
          font: var(--w3-heading);
        }

        .login-context p  {
          font: var(--w4-info);
        }

        .login-context svg {
          height: 32px;
          width: 32px;
        }
      }
    </style>

    <header class="login-context">
      <div class="icon">
        <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path fill="currentColor" d="${loginContext.iconPath}" />
        </svg>
      </div>
      <h1>${loginContext.title}</h1>
      <p>${loginContext.description}</p>
    </header>
    `.replace(/\s{2,}/g,''); // makeshift minification, due to lack of proper tooling
}
