import { AmplifyAuthWrapper } from "./amplifyAuthWrapper";
import { AuthJsError } from "./AuthJsError";
import { AuthUi } from "./authUi";
import { Logger } from "./log";
import { Notifier } from "./notifier";
import type { CognitoUserSession } from "amazon-cognito-identity-js";

const log = new Logger({ logger: "authApi" });

type Args = {
  amplifyAuthWrapper: DstAuth.AmplifyWrapper
};

/**
 * Provides access to Auth functionality
 */
class AuthApi {

  #amplify
  #authUi

  static StateInitialSession = "dst.auth.initialSession";

  constructor({ amplifyAuthWrapper }: Args) {

    const isDe = document.location.hostname.includes(process.env.DE_HOSTNAME_PATTERN || "");
    const signInUri = isDe ? process.env.SIGNIN_URI_DE : process.env.SIGNIN_URI;
    log.debug("Initialize AuthApi", { isDe, signInUri });

    window.addEventListener("message", async (event) => {

      if (event?.data?.eventName === Notifier.StateChangedEvent) {

        this.onStateChanged(await this.getSession());
      }
    });

    this.#authUi = new AuthUi({ signInUri: signInUri });
    this.#amplify = amplifyAuthWrapper || new AmplifyAuthWrapper();

    log.debug("Get session initially");
    this.#amplify.getSession().then((initialSession) => {
      this.#postMessage(AuthApi.StateInitialSession, initialSession);
    });
  }

  /**
   * Returns current user session, if any. Otherwise null.
   * @returns {Promise<CognitoUserSession>} - current user session
   */
  async getSession() {

    // check for cognito session
    // implicitly refreshes, if expired
    log.debug("getSession triggered");
    return this.#amplify.getSession();
  }

  /**
   * Triggers sign-in and enables the user to authenticate
   * @param {Object} [options] - Options
   * @param {string} [options.triggerType=pageload] - How sign-in will be triggered ("pageload", "overlay")
   * @param {Object} [options.pageloadOptions] - PageloadOptions
   * @param {Object} [options.pageloadOptions.target=document.location.href] - Target URI to redirect to, after successful signIn
   * @param {Object} [options.overlayOptions] - OverlayOptions
   * @param {Object} [options.overlayOptions.loginContext] - Indicates whether a login context should be passed to the overlay
   * @param {Object} [options.overlayOptions.noCloseButton=false] - Indicates whether close button on overlay should be rendered
   * @throws {AuthJsError} - on invalid parameters
   */
  async signIn(options = {

    triggerType: "pageload",
    pageloadOptions: {

      target: document.location.href
    },
    overlayOptions: {

      loginContext: undefined,
      noCloseButton: false
    }
  }) {

    log.debug("signIn triggered", options);

    if ((!options.triggerType || options.triggerType === "pageload") && !options.pageloadOptions) {

      throw new AuthJsError("Missing parameter: pageloadOptions");
    }

    if (options.triggerType === "overlay" && !options.overlayOptions) {

      throw new AuthJsError("Missing parameter: overlayOptions");
    }

    const session = await this.#amplify.getSession();
    if (session) {

      log.info("already signed in");
      this.onSignedIn(session);
      return;
    }

    switch (options.triggerType) {

      case "overlay": {

        const { loginContext, noCloseButton = false } = options.overlayOptions;
        const onOverlaySuccess = () => this.#amplify.getSession().then((session) => this.onSignedIn(session));
        this.#authUi.openSignInOverlay({ loginContext, noCloseButton, onOverlaySuccess });
        break;
      }

      case "pageload":
      default: {

        const { target = document.location.href } = options.pageloadOptions;
        this.#authUi.openSignInPageload({ target });
      }
    }
  }

  /**
   * Trigger sign-out and clears the current user session
   * @param {Object} [options] - Options
   * @param {boolean} [options.global=false] - Signs out users from all devices. It also invalidates all refresh tokens issued to a user.
   */
  async signOut(options = { global: false }) {

    log.debug("signOut triggered", options);
    return this.#amplify.signOut(options);
  }

  /**
   * This callback fires when a user successfully signed in
   * @param {CognitoUserSession} session - current user session
   */
  onSignedIn(session: CognitoUserSession | null) {

    log.debug("onSignedIn callback", session);
  }

  /**
  * This callback fires when a user's state changes (e.g. tokenRefresh, signOut, also in another tab)
  * @param {?CognitoUserSession} session - current user session, if any
  */
  onStateChanged(session: CognitoUserSession | null) {

    log.debug("onStateChanged callback", session);
  }

  /**
   * Origins can be defined in a pipe-separated string in the `TARGET_ORIGIN_ALLOWLIST` environment variable.
   * For development purposes, the value can be set to "*" to allow all origins.
   * @returns {string} - target origin
   */
  #getTargetOrigin(): string | null {

    let targetOrigins = process.env.TARGET_ORIGIN_ALLOWLIST;

    if (targetOrigins === "*") {
      return "*";
    }

    const originsArray = (targetOrigins || "").split("|");
    for (const targetOrigin of originsArray) {
      let url;

      try {
        url = new URL(targetOrigin);
      } catch (error) {
        throw new TypeError("Invalid URL provided as target origin");
      }

      if (url.origin !== targetOrigin) {
        throw new TypeError("Invalid origin provided as target");
      }

      if (window.location.origin === targetOrigin) {
        return targetOrigin;
      }
    };

    return null;
  }

  /**
   * Sends a message to allowed target origins.
   * This is used to send the initial session data to subscribers and to notify about session changes.
   * @param {string} eventName - name of the event
   * @param {*} payload - event payload
   */
  #postMessage(eventName: string, payload: CognitoUserSession | null) {

    const targetOrigin = this.#getTargetOrigin();

    if (!targetOrigin) {
      throw new Error("No valid target origin found");
    }

    window.postMessage({
      eventName,
      payload
    }, targetOrigin);
  }
}

export default AuthApi;
