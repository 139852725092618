const settings: DstAuth.Settings = {
  buildId: process.env.BUILD_ID_PUBLIC,
  awsUserPoolsId: process.env.AWS_USER_POOLS_ID,
  awsUserPoolsWebClientId: process.env.AWS_USER_POOLS_WEB_CLIENT_ID,
  cookieDomain: process.env.COOKIE_DOMAIN,
  cookieDomainDe: process.env.COOKIE_DOMAIN_DE,
  cookieExpiryDays: parseInt(process.env.COOKIE_EXPIRY_DAYS || "", 10),
  cookieUseSecure: process.env.COOKIE_USE_SECURE === "true",
  iamFormDomid: process.env.IAM_FORM_DOMID,
  iamUsernameDomid: process.env.IAM_USERNAME_DOMID,
  iamPwdDomid: process.env.IAM_PWD_DOMID,
  cookieDeletionEnabled: process.env.COOKIE_DELETION_ENABLED === "true",
  cookieDeletionDomain: process.env.COOKIE_DELETION_DOMAIN, // ".derstandard.at"
  deHostnamePattern: process.env.DE_HOSTNAME_PATTERN || null,
  isDe: document.location.hostname.includes(process.env.DE_HOSTNAME_PATTERN || ""),
  tokenWorkflowUrlWhitelist: process.env.TOKENWORKFLOW_URL_WHITELIST || ""
};

export {
  settings
};
