/**
 * AuthJsError
 */
class AuthJsError extends Error {
  /**
   * Creates new instance
   * @param {string} message - Exception message
   */
  constructor(message: string) {
    super(message);
    this.name = this.constructor.name;
  }
}

export {
  AuthJsError
};
