import { default as CookieAndLocalStorage } from "./cookieAndLocalStorage";
import { settings } from "./settings";

export const config = {
  userPoolId: settings.cognito.userPoolId,
  userPoolWebClientId: settings.cognito.clientId,
  authenticationFlowType: "USER_PASSWORD_AUTH",
  storage: new CookieAndLocalStorage({
    domain: settings.isDe ? String(settings.cookieDomainDe) : String(settings.cookieDomain),
    path: "/",
    expires: 2555,
    secure: Boolean(settings.cookieUseSecure),
    cookieKeySuffixes: "LastAuthUser,refreshToken,idToken,deviceKey"
  }),
  // Hosted UI Configuration
  "oauth": {}
} as const;
