/**
 * Basic logger
 */

enum LogEnums {

  Debug = "debug",
  Info = "info",
  Error = "error",
  Warn = "warn"
}

class Logger {

  static #loggerNamespace = process.env.APP_NAME;
  static #logLevels: DstAuth.Logger = {

    "debug": 0,
    "info": 1,
    "warn": 2,
    "error": 3,
    "off": 99
  };
  propsCallback: DstAuth.PropsCallback | null;

  static #defaultSetLevel() {

    return window.localStorage.getItem("debugMode") === "true" ? "debug" : process.env.LOG_LEVEL || "warn";
  }

  #logger
  #setLevel

  /**
   *
   * @param {Object} options
   * @param {string} options.logger - Name of logger
   * @param {function} [options.propsCallback] - Callback that provides props to be included in every message
   * @param {string} [options.setLevel] - Set minimal level for this logger
   */
  constructor({ logger = "", propsCallback = null, setLevel = Logger.#defaultSetLevel() }) {

    this.#logger = `${Logger.#loggerNamespace}/${logger}`;
    this.#setLevel = setLevel;
    this.propsCallback = propsCallback;
  }

  #log(level: LogEnums, ...args: any[]) {

    // decide for other targets here
    this.#logMessageToConsole(level, ...this.#createMessage(this.#logger, ...args));
  }

  #createMessage(logger: string, ...args: any[]) {

    const message = [`[${logger}]`, ...args];
    if (typeof this.propsCallback === "function") {

      const props = this.propsCallback();
      message.push(props);
    }

    return message;
  }

  #logMessageToConsole(level: LogEnums, ...message: any[]) {

    if (Logger.#logLevels[level] >= Logger.#logLevels[this.#setLevel]) {

      console[level](...message);
    }
  }

  /**
   * Debug
   * @param  {...any} args
   */
  debug(...args: any[]) {

    this.#log(LogEnums.Debug, ...args);
  }

  /**
   * Info
   * @param  {...any} args
   */
  info(...args: any[]) {

    this.#log(LogEnums.Info, ...args);
  }

  /**
   * Warn
   * @param  {...any} args
   */
  warn(...args: any[]) {

    this.#log(LogEnums.Warn, ...args);
  }

  /**
   * Error
   * @param  {...any} args
   */
  error(...args: any[]) {

    this.#log(LogEnums.Error, ...args);
  }
}

/**
 * @deprecated
 * Pushes object to dataLayer. Initializes new dataLayer Object, if it doesn't exist yet.
 * @param {Object} obj
 */
function logToDataLayer(obj: object) {

  if (!obj || typeof obj !== "object") {
    return;
  }
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(obj);
}

/**
 * @deprecated
 * @param {string} loggerName
 * @returns {Logger} logger
 */
function createLog(loggerName: string) {

  return new Logger({ logger: loggerName });
}

export {

  Logger,
  createLog,
  logToDataLayer
};
