import { Logger } from "./log";
import * as Cookies from "js-cookie";

const log = new Logger({ logger: "CookieAndLocalStorage"});

/** @class */
export default class CookieAndLocalStorage {
  domain: DstAuth.CookieData["domain"];
  path: DstAuth.CookieData["path"];
  expires: DstAuth.CookieData["expires"];
  secure: DstAuth.CookieData["secure"];
  cookieKeySuffixes: string[];
  /**
   * Constructs a new CookieStorage object
   * @param {object} data Creation options.
   * @param {string} data.domain Cookies domain (mandatory).
   * @param {string} data.path Cookies path (default: '/')
   * @param {integer} data.expires Cookie expiration (in days, default: 365)
   * @param {boolean} data.secure Cookie secure flag (default: true)
   * @param {string} data.cookieKeySuffixes comma separated suffixes for keys which should be stored as cookie, otherwise localStorage (default: none)
   */
  constructor(data: DstAuth.CookieData) {

    log.info("Creating cookieStorage...");
    if (data.domain) {

      this.domain = data.domain;
    } else {

      throw new Error("The domain of cookieStorage can not be undefined.");
    }
    if (data.path) {

      this.path = data.path;
    } else {

      this.path = "/";
    }
    if (Object.prototype.hasOwnProperty.call(data, "expires")) {

      this.expires = data.expires;
    } else {

      this.expires = 365;
    }
    if (Object.prototype.hasOwnProperty.call(data, "secure")) {

      this.secure = data.secure;
    } else {

      this.secure = true;
    }
    if (data.cookieKeySuffixes) {

      this.cookieKeySuffixes = data.cookieKeySuffixes.split(",");
    }  else {

      this.cookieKeySuffixes = [];
    }

    if (!document.location.hostname.endsWith(this.domain)) {

      log.warn(`Cookie domain '${this.domain}' not compatible with document location!`);
    }
  }

  /**
   *
   * @param {*} key
   * @returns {boolean} indicates whether cookie should be written
   */
  writeCookieFor(key: string) {

    const keyArray = key.split(".");
    const keySuffix = keyArray[keyArray.length - 1];

    const writeCookie = this.cookieKeySuffixes.includes(keySuffix);
    //log.info(`cookie? ${key} => ${writeCookie}`);
    return writeCookie;
  }

  /**
   *
   * @param {*} key
   * @returns {string} key which is used for cookie storage
   */
  getCookieKey(key: string) {

    // const fullname = "CognitoIdentityServiceProvider";
    // const shortName = "cgnt";

    // if (key.indexOf(fullname) > -1) {

    //   return key.replace(fullname, shortName);
    // }

    return key;
  }

  /**
   * This is used to set a specific item in storage
   * @param {string} key - the key for the item
   * @param {object} value - the value
   * @returns {string} value that was set
   */
  setItem(key: string, value: string) {

    if (!this.writeCookieFor(key)) {

      log.debug(`Set localStorage '${key}'...`);
      localStorage.setItem(key, value);
      return localStorage.getItem(key);
    }

    // don't overwrite the same data
    if (Cookies.get(key) === value) {

      return value;
    }

    const cookieKey = this.getCookieKey(key);
    log.debug(`Set cookie '${cookieKey}'...`);
    Cookies.set(cookieKey, value, {

      path: this.path,
      expires: this.expires,
      domain: this.domain,
      secure: this.secure
    });
    return Cookies.get(key);
  }

  /**
   * This is used to get a specific key from storage
   * @param {string} key - the key for the item
   * This is used to clear the storage
   * @returns {string} the data item
   */
  getItem(key: string) {

    if (!this.writeCookieFor(key)) {

      // migrate from cookie start
      const cookieKey = this.getCookieKey(key);
      const cookieValue = Cookies.get(cookieKey);

      if (cookieValue) {

        log.debug(`Migrating cookie to localStorage '${key}'...`);
        localStorage.setItem(key, cookieValue);
        Cookies.remove(cookieKey, {

          path: this.path,
          domain: this.domain,
          secure: this.secure
        });
        return localStorage.getItem(key);
      }
      // migrate end

      log.debug(`Get localStorage '${key}'...`);
      return localStorage.getItem(key);
    }

    const cookieKey = this.getCookieKey(key);
    log.debug(`Get cookie '${cookieKey}'...`);
    return Cookies.get(cookieKey);
  }

  /**
   * This is used to remove an item from storage
   * @param {string} key - the key being set
   * @returns {string} value - value that was deleted
   */
  removeItem(key: string) {

    if (!this.writeCookieFor(key)) {

      log.debug(`Remove localStorage '${key}'...`);
      return localStorage.removeItem(key);
    }

    const cookieKey = this.getCookieKey(key);
    log.debug(`Remove cookie '${cookieKey}'...`);
    return Cookies.remove(cookieKey, {

      path: this.path,
      domain: this.domain,
      secure: this.secure
    });
  }

  /**
   * This is used to clear the storage
   * @returns {string} nothing
   */
  clear() {
    log.info("Clearing localStorage...");
    localStorage.clear();

    log.info("Clearing all cookies...");
    const cookies = Cookies.get();
    let index: number;
    for (index = 0; index < Object.keys(cookies).length; ++index) {

      Cookies.remove(cookies[index]);
    }
    return {};
  }
}
